<style scoped>
.table {
  font-size: 0.7em;
}
.font-smaller {
  font-size: 0.8em;
}
</style>

<template>
  <div class="mt-3">
    <option-meals-editing
      v-bind:meals="editMeal"
      v-bind:tot="totOptions"
      v-on:meals-edited="getSettings()"
    />

    <h4>Settings</h4>

    <b-alert show dismissible fade variant="danger">
      <p>IMPORTANT: This screen has been updated in version 5.0.</p>
      <p>After making changes in this page, 
        the app will not work properly in devices 
        using version 4.9 or lower. 
        Therefore, make sure all residents have 
        updated the app to the last version. 
        You may see the current version number 
        next to the name of the Center.</p>
    </b-alert>

    <b-form class="mt-5">
      <!-- Options for Meals -->
      <h4 class="text-left">What options for meals do you want displayed?</h4>

      <!-- Breakfast Options -->
      <h5 class="text-left mt-4">Breakfast</h5>
      <div style="max-width: 480px">
        <b-table
          striped
          small
          hover
          ref="breakfast"
          :items="mealOptions['1']"
          :fields="fields"
          :key="mealOptions['1'].id"
          selectable
          select-mode="single"
          v-on:row-selected="rowSelected($event)"
        >
          <template #cell(color)="data">
            <span>
              <b-badge pill :variant="data.unformatted">{{
                data.value
              }}</b-badge>
            </span>
          </template>
          <template #cell(Active)="data">
            <font-awesome-icon
              v-if="data.value == 1"
              icon="eye"
              size="lg"
              class="ml-1 icons"
              color="green"
            /><font-awesome-icon
              v-else
              icon="eye-slash"
              size="lg"
              class="ml-1 icons"
              color="red"
            />
          </template>
        </b-table>
        <b-button
          size="sm"
          class="text-right"
          @click="onAdd('1')"
          variant="primary"
          >Add Option</b-button
        >
      </div>

      <!-- Lunch Options -->
      <h5 class="text-left mt-4">Lunch</h5>
      <div style="max-width: 480px">
        <b-table
          striped
          small
          hover
          ref="lunch"
          :items="mealOptions['2']"
          :fields="fields"
          :key="mealOptions['2'].id"
          selectable
          select-mode="single"
          v-on:row-selected="rowSelected($event)"
        >
          <template #cell(color)="data">
            <span>
              <b-badge pill :variant="data.unformatted">{{
                data.value
              }}</b-badge>
            </span>
          </template>
          <template #cell(Active)="data">
            <font-awesome-icon
              v-if="data.value == 1"
              icon="eye"
              size="lg"
              class="ml-1 icons"
              color="green"
            /><font-awesome-icon
              v-else
              icon="eye-slash"
              size="lg"
              class="ml-1 icons"
              color="red"
            />
          </template>
        </b-table>
        <b-button
          size="sm"
          class="text-right"
          @click="onAdd('2')"
          variant="primary"
          >Add Option</b-button
        >
      </div>

      <!-- Dinner Options -->
      <h5 class="text-left mt-4">Dinner</h5>
      <div class="mb-5" style="max-width: 480px">
        <b-table
          striped
          small
          hover
          ref="dinner"
          :items="mealOptions['3']"
          :fields="fields"
          :key="mealOptions['3'].id"
          selectable
          select-mode="single"
          v-on:row-selected="rowSelected($event)"
        >
          <template #cell(color)="data">
            <span>
              <b-badge pill :variant="data.unformatted">{{
                data.value
              }}</b-badge>
            </span>
          </template>
          <template #cell(Active)="data">
            <font-awesome-icon
              v-if="data.value == 1"
              icon="eye"
              size="lg"
              class="ml-1 icons"
              color="green"
            /><font-awesome-icon
              v-else
              icon="eye-slash"
              size="lg"
              class="ml-1 icons"
              color="red"
            />
          </template>
        </b-table>
        <b-button
          size="sm"
          class="text-right"
          @click="onAdd('3')"
          variant="primary"
          >Add Option</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import OptionMealsEditing from "../../components/OptionMealsEditing.vue";
export default {
  data() {
    return {
      mealOptions: { 1: [], 2: [], 3: [] },
      totOptions: "0",
      fields: [
        { key: "type", label: "Initial" },
        { key: "Label", label: "Label" },
        {
          key: "color",
          label: "Color",
          formatter: (color) => {
            switch (color) {
              case "success":
                return "Green";
              case "warning":
                return "Yellow";
              case "danger":
                return "Red";

              default:
                return "";
            }
          },
        },
        {
          key: "Active",
          label: "Active",
        },
      ],
      editMeal: {
        id: "0",
        Time: "1",
        type: "X",
        Label: "At Table",
        color: "success",
        Priority: "1",
        Active: "1",
      },
    };
  },
  computed: {},
  mounted() {
    this.getSettings();
  },
  components: { OptionMealsEditing },
  methods: {
    getSettings() {
      var url = "getMealOptions.php";
      var data = {};
      this.$http.post(url, data, { emulateJSON: true }).then((res) => {
        this.mealOptions = res.body;
      });
    },
    onAdd(timeMeal) {
      this.$set(this.editMeal, "id", "0");
      this.$set(this.editMeal, "Time", timeMeal);
      this.$set(this.editMeal, "type", "");
      this.$set(this.editMeal, "Label", "");
      this.$set(this.editMeal, "color", "warning");
      let priority = this.mealOptions[timeMeal].length + 1;
      this.$set(this.editMeal, "Priority", priority.toString());
      this.$set(this.editMeal, "Active", "1");
      this.totOptions = this.mealOptions[timeMeal].length.toString();
      this.$bvModal.show("modal-meals");
    },
    rowSelected(e) {
      if (e.length > 0) {
        var sel = e[0];
        this.$set(this.editMeal, "id", sel.id);
        this.$set(this.editMeal, "Time", sel.Time);
        this.$set(this.editMeal, "type", sel.type);
        this.$set(this.editMeal, "Label", sel.Label);
        this.$set(this.editMeal, "color", sel.color);
        this.$set(this.editMeal, "Priority", sel.Priority);
        this.$set(this.editMeal, "Active", sel.Active);
        this.totOptions = this.mealOptions[sel.Time].length.toString();
        this.$bvModal.show("modal-meals");
        this.$refs.breakfast.clearSelected();
        this.$refs.lunch.clearSelected();
        this.$refs.dinner.clearSelected();
      }
    },
  },
};
</script>
