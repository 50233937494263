<template>
  <b-modal id="modal-meals" title="Option Meals" @show="loadData()" hide-footer>
    <b-form
      class="mx-auto"
      v-on:submit.prevent="onSubmit"
      style="max-width: 380px"
    >
      <!-- Initial -->
      <b-form-row class="my-1">
        <b-col cols="4"><label>Initial</label> </b-col>
        <b-col cols="8"
          ><b-form-input
            v-model="newOptions.type"
            type="text"
            maxlength="1"
            :plaintext="newOptions.type == 'O'"
          ></b-form-input>
        </b-col>
      </b-form-row>
      <!-- Label -->
      <b-form-row class="my-1">
        <b-col cols="4"><label>Label</label> </b-col>
        <b-col cols="8"
          ><b-form-input v-model="newOptions.Label" type="text"></b-form-input>
        </b-col>
      </b-form-row>
      <!-- Color -->
      <b-form-row class="my-1">
        <b-col cols="4"><label>Color</label> </b-col>
        <b-col cols="8"
          ><b-form-select
            v-model="newOptions.color"
            :options="options"
          ></b-form-select>
        </b-col>
      </b-form-row>
      <!-- Active -->
      <b-form-row class="my-1">
        <b-col cols="4"><label>Active</label> </b-col>
        <b-col cols="8"
          ><b-form-checkbox
            v-model="newOptions.Active"
            value="1"
            unchecked-value="0"
          ></b-form-checkbox>
        </b-col>
      </b-form-row>
      <b-alert v-if="newOptions.type == 'O'" show variant="info" class="my-3"
        >Note: 'O' option can not be edited or deleted.</b-alert
      >
      <!-- Buttons -->
      <div class="text-center mt-3">
        <b-button type="submit" variant="primary">Submit</b-button>
        <b-button class="ml-1" @click="onCancel" variant="warning"
          >Cancel</b-button
        >
        <b-button
          v-if="newOptions.type != 'O' && newOptions.id != '0'"
          class="ml-1"
          @click="onDelete()"
          variant="danger"
          >Delete</b-button
        >
      </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  props: { meals: Object, tot: String },

  data: function () {
    return {
      newOptions: {
        id: "0",
        Time: "1",
        type: "X",
        Label: "At Table",
        color: "success",
        Priority: "1",
        Active: "1",
      },
      options: [
        { value: "success", text: "green" },
        { value: "danger", text: "red" },
        { value: "warning", text: "yellow   " },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    onSubmit() {
      var url = "updateMealOptions.php";
      this.newOptions.type = this.newOptions.type.toUpperCase();
      let data = { ...this.newOptions, tot: this.tot };
      this.$http.post(url, data, { emulateJSON: true }).then(
        (res) => {
          //TODO update meals in vuex
          this.$store.commit("loadMealOptions", res.body.settings.MealOptions);
          this.$bvToast.toast(`Updated`, {
            title: "Meal Options",
            variant: "info",
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            appendToast: false,
          });
          this.$emit("meals-edited");
        },
        // eslint-disable-next-line no-unused-vars
        (err) => {}
      );
      this.$bvModal.hide("modal-meals");
    },
    onCancel: function () {
      this.$bvModal.hide("modal-meals");
    },
    onDelete() {
      var url = "deleteMealOptions.php";
      var data = { id: this.newOptions.id };
      this.$http.post(url, data, { emulateJSON: true }).then((res) => {
        this.$store.commit("loadMealOptions", res.body.settings.MealOptions);
        this.$emit("meals-edited");
        this.$bvToast.toast(`Deleted`, {
            title: "Meal Options",
            variant: "info",
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            appendToast: false,
          });
        this.$bvModal.hide("modal-meals");
      });
    },
    loadData: function () {
      this.newOptions.id = this.meals.id;
      this.newOptions.Time = this.meals.Time;
      this.newOptions.type = this.meals.type;
      this.newOptions.Label = this.meals.Label;
      this.newOptions.color = this.meals.color;
      this.newOptions.Priority = this.meals.Priority;
      this.newOptions.Active = this.meals.Active;
    },
  },
};
</script>

<style>
</style>